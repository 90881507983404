import revive_payload_client_VbJI0HTDqG from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._p672jkyzvdb2msfi3l7p656pxe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_InoAGpKc9x from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._p672jkyzvdb2msfi3l7p656pxe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zTjt6Ywvsr from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._p672jkyzvdb2msfi3l7p656pxe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_fR3IYWkmWi from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._p672jkyzvdb2msfi3l7p656pxe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_xIXiq1Viho from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._p672jkyzvdb2msfi3l7p656pxe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_FnMVQXoobC from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._p672jkyzvdb2msfi3l7p656pxe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_7YdGNrDDyl from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._p672jkyzvdb2msfi3l7p656pxe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/platform-admin/.nuxt/components.plugin.mjs";
import prefetch_client_mEkPvQ7maX from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._p672jkyzvdb2msfi3l7p656pxe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_9gq5bINlCQ from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.22.5_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import tooltip_BSdd3GyCvN from "/vercel/path0/node_modules/.pnpm/@docue+docue-ui-v2@3.2.0_magicast@0.3.5_nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.1_7g3jhqyjn6lsdgfxetdvg5c5ou/node_modules/@docue/docue-ui-v2/ui-plugins/tooltip.ts";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/apps/platform-admin/plugins/bugsnag.ts";
export default [
  revive_payload_client_VbJI0HTDqG,
  unhead_InoAGpKc9x,
  router_zTjt6Ywvsr,
  payload_client_fR3IYWkmWi,
  navigation_repaint_client_xIXiq1Viho,
  check_outdated_build_client_FnMVQXoobC,
  chunk_reload_client_7YdGNrDDyl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mEkPvQ7maX,
  plugin_9gq5bINlCQ,
  tooltip_BSdd3GyCvN,
  bugsnag_tkMQfOjcVZ
]